var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _c("ComponentDeveloperUploadStaffList"),
      _c("ComponentStaffListExcel"),
      _c("v-row", { staticClass: "ma-2" }, [
        _c("span", { staticStyle: { "font-size": "20px" } }, [
          _vm._v(" Below is the new component for the staff list "),
        ]),
      ]),
      _c("ComponentStaffListv2"),
      _c("ComponentStaffListMatched"),
      _c("ComponentStaffListUnmatched"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }